/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/index.scss"
import "normalize.css"
import "./src/layouts/layout.css"
import "./src/styles/animate.css"
import BUILD_VERSION from "./BUILD_VERSION"

// print build version under console
export function onClientEntry() {
  console.log("We've started!")
  console.log("BUILD VERSION: ", BUILD_VERSION)
}
